import FileDownload from '../assets/Icons/File_Download.svg'
import Nodejs from '../assets/Icons/Nodejs.svg'
import React from '../assets/Icons/React.svg'
import ReactNative from '../assets/Icons/ReactNative.svg'
import Redirect from '../assets/Icons/Redirect.svg'
import Close from '../assets/Icons/close.svg'
import DevOps from '../assets/Icons/Devops.svg'
import Hamburger from '../assets/Icons/hamburger.svg'
import Linkedin from '../assets/Icons/linkedin.svg'
import Gmail from '../assets/Icons/gmail.svg'


export const Icons = {
    FileDownload,
    Close,
    Redirect,
    React,
    Nodejs,
    ReactNative,
    DevOps,
    Hamburger,
    Linkedin,
    Gmail
}





const timelineData = [
  {
    id: 0,
    text: 'Wrote my first blog post ever on Medium 1',
    date: 'March 03 2017',
    category: {
      tag: 'medium',
      color: '#9EA1D4'
    },
    link: {
      url:
        'https://medium.com/@popflorin1705/javascript-coding-challenge-1-6d9c712963d2',
      text: 'Read more'
    }
  },
  {
    id: 1,
    text: 'Wrote my first blog post ever on Medium 2',
    date: 'March 03 2017',
    category: {
      tag: 'medium',
      color: '#018f69'
    },
    link: {
      url:
        'https://medium.com/@popflorin1705/javascript-coding-challenge-1-6d9c712963d2',
      text: 'Read more'
    }
  },
  {
    id: 2,
    text: 'Wrote my first blog post ever on Medium 3',
    date: 'March 03 2017',
    category: {
      tag: 'medium',
      color: '#3A98B9'
    },
    link: {
      url:
        'https://medium.com/@popflorin1705/javascript-coding-challenge-1-6d9c712963d2',
      text: 'Read more'
    }
  },
  {
    id: 3,
    text: 'Wrote my first blog post ever on Medium 4',
    date: 'March 03 2017',
    category: {
      tag: 'medium',
      color: '#FF6969'
    },
    link: {
      url:
        'https://medium.com/@popflorin1705/javascript-coding-challenge-1-6d9c712963d2',
      text: 'Read more'
    }
  }
]


export default timelineData
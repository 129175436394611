const headerTabsData = [
    {
        tabName: 'Home',
        route: '/'
    },
    {
        tabName: 'Blogs',
        route: '/blog'
    },
    {
        tabName: 'Works',
        route: '/myWorks'
    },
    {
        tabName: 'About',
        route: '/about'
    },
    // {
    //     tabName: 'Contact',
    //     route: '/contact'
    // },

]


export default headerTabsData
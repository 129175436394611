const ProjectsData = [
  {
    id: 1,
    name: "IPL Dashboard",
    description:
      "Dashboard of IPL Teams. we can see match details and player names and all details related to the specific team",
    imageUrl: "https://i.ibb.co/YNZ0V24/ipl.png",
    link: "https://kandulareact24.ccbp.tech/",
    technology: "React",
  },
  {
    id: 2,
    name: "Emoji Game",
    description: `Select the non-identical emoji's user should select the non-identical Emojis`,
    imageUrl: "https://i.ibb.co/4sL4gwn/Emoji.png",
    link: "https://kandulareact18.ccbp.tech/",
    technology: "React",
  },
  {
    id: 3,
    name: "Events Registration",
    description: "Dashboard for Registration of Cultural, Dance Events",
    imageUrl: "https://i.ibb.co/7t52fcM/Events.png",
    link: "https://kandulareact26.ccbp.tech/",
    technology: "React",
  },
  {
    id: 4,
    name: "Appointment Scheduler",
    description: "Dashboard to Save Appointments",
    imageUrl: "https://i.ibb.co/GVCFHFn/Appointment.png",
    link: "https://kandulareact16.ccbp.tech/",
    technology: "React",
  },
  {
    id: 5,
    name: "Money Manager",
    description: "Quick and Easy Expenses and Savings Manager",
    imageUrl: "https://i.ibb.co/vQzZ2tN/Money-Manager.png",
    link: "https://kandulareact17.ccbp.tech/",
    technology: "React",
  },
];

export default ProjectsData;

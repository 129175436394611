import React from 'react'
import '../Styles/Loader.css'

const Loader = () => {
  return (
    <div className='loader'>
      <div className='spinner'></div>
    </div>
  )
}

export default Loader

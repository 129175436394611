const blogsData = [
  {
    blog_id: 1,
    title: 'Post 1',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imageUrl:
      'https://img.freepik.com/free-vector/cartoon-web-design-landing-page_52683-70880.jpg?w=2000',
    link: 'post1.com'
  },
  {
    blog_id: 2,
    title: 'Post 2',
    content:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
    imageUrl:
      'https://images.hindustantimes.com/img/2022/10/28/1600x900/IMG_8173_1666970942735_1666970949855_1666970949855.jpg',
    link: 'post2.com'
  },
  {
    blog_id: 3,
    title: 'Post 3',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.',
    imageUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTATO4QskYJo3d6mfzkDUyKbv9TK38ZP7ZgrseU7UamCBiYHwZO6UAff2peBFSX1_tLQQk&usqp=CAU',
    link: 'post3.com'
  }
]


export default blogsData